[hero] {
    margin: 7em auto 0 auto;
    color: white;
    font-family: inherit;
}

[hero] > div > h1 {
    margin-top: 0;
    color: white;
}


[hero] > div > ul {
    margin-bottom: 5em; 
}


[btn~="hero"]  {
    margin-bottom: 5em;
    margin-top: 1em;
    display: inline-block;
    width: auto;
}

.bg-gradient {
    background: linear-gradient(to right,$primary-light, $primary);
}


@include media("<=medium") {
    [hero] {
        margin: 3em auto 0 auto;
    } 

    [btn~="hero"]  {
        margin-top: 1.5em;
        margin-bottom: 2.5em;        
    }
}

@include media("<=small") {
    [hero] {
        margin: 2em auto 0 auto;
    }

    [hero] > div > h1 {
        max-width: none;
    }

    
    [hero] > div > ul {
        margin-bottom: 2em; 
    }


    [btn~="hero"]  {
        margin-top: 1.5em;
        margin-bottom: 1.5em;        
    }
}

@include media("<=550px") {
    [hero] {
        grid-template-columns: 1fr;
    }

    [hero] > div > p {
        width:320px;
    }
}