[services] {
    margin: 4em auto; 
    
}

[services] h1 {
    color: $primary;
    margin-bottom: 2em;
}

[services] div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2em;
}

[services] h3 {
    margin: 0;    
    white-space: nowrap;
    // color: $primary;
    font-weight: 400;
    // text-align: center;

}


[services] h3::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background: linear-gradient(to right,$primary-light, $primary);
    
}

.number {
    // border: 1px solid red;
    // text-align: center;
    font-weight: 700;
    color: #eaeaea;
    font-size: 5em;
    margin: 0 0 -75px 0;
}


[services] p {
    margin-top: 0;
}

@include media(">=medium", "<=large") {
    
    [services] div {
        grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
    }

}


