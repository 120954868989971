.section-title{
    --section-height: 40vh;

    height: var(--section-height);
    max-width: 1920px;
    width: 100%;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: -1;
}

.section-title::before{
    content:'';
    display: block;
    width: 100vw;
    height: var(--section-height);
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(33,114,230,0.8);    
}

// .section-title::after{
//     content:'';
//     display: block;
//     width: 400vw;
//     height: 400vw;
//     position: absolute;
//     bottom: -200px;
//     left: -145vw;
//     border: 200px solid white;
//     border-radius: 100%;
// }



.section-title__heading {
    position: relative;
    z-index: var(--section-title__heading-z-index);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-weight: 700;
    text-align: center;
}