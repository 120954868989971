// Variables - Globals
$global-font-family:'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$global-border-radius: 3px;
$global-grid-gap: 2em;
$global-text-color: #636363;

//// Variables - Colors
$primary: #2347ba;
$primary-light: #2172e6; 

:root {

    /* ----- Colors ----- */

    --primary: #2347ba;
    --primary-light: #2172e6; 

    /* ----- Header ----- */

    --header-bg: transparent;


    /* ----- Spacers ----- */

    --space-unit: 1em;
    --space-xxxxs: calc(0.237 * var(--space-unit));
    --space-xxxs: calc(0.316 * var(--space-unit));
    --space-xxs: calc(0.422 * var(--space-unit));
    --space-xs: calc(0.563 * var(--space-unit));
    --space-sm: calc(0.75 * var(--space-unit));
    --space-md: calc(1 * var(--space-unit));
    --space-lg: calc(1.333 * var(--space-unit));
    --space-xl: calc(1.777 * var(--space-unit));
    --space-xxl: calc(2.369 * var(--space-unit));
    --space-xxxl: calc(3.157 * var(--space-unit));
    --space-xxxxl: calc(4.21 * var(--space-unit));

    /* ----- Others ----- */
    --global-border-radius: 3px;

}

// Reset 
html {
box-sizing: border-box;
}

*, *:before, *:after {
box-sizing: inherit;
}

/* ========= Body ========= */
body {
    font-family: $global-font-family;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


footer {
    margin-top: auto;
}
// Global Styles 

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

h1, h2, h3 {
    font-weight: 100;
}


@include media("<=medium") {

    h1 {
        font-size: 2.5em;
    }

    h2 {
        font-size: 1.6em;
    }
    
}

@include media("<=small") {

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.3em;
    }
}

a {
    text-decoration: none;
    color: inherit;    
}

p {
    line-height: 1.4;
}

canvas,
img,
video {
    max-width: 100%;
    height: auto;
}

// Layout
.container {
    max-width: 90%;
    margin: 0 auto;
}

.container-small{
    max-width: 70%;
    margin: 0 auto;
}



.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: $global-grid-gap;
}

section {
    max-width: 1920px;
    margin: 0 auto;
}


.my-xxxxl{ margin: var(--space-xxxxl) auto}


.grid-main {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
}

.one, .two, .three, .four, .five, .six,
.seven, .eight, .nine, .ten, .eleven, .twelve {
    grid-column-end: span 12;
}

.nested {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
}

.merge-two-rows { grid-row-end: span 2 }
.merge-three-rows { grid-row-end: span 3 }
.merge-four-rows { grid-row-end: span 4 }
.merge-five-rows { grid-row-end: span 5 }
.merge-six-rows { grid-row-end: span 6 }

@media only screen and (min-width: 481px) {
    .one { grid-column-end: span 1 }
    .two { grid-column-end: span 2 }
    .three { grid-column-end: span 3 }
    .four { grid-column-end: span 4 }
    .five { grid-column-end: span 5 }
    .six { grid-column-end: span 6 }
    .seven { grid-column-end: span 7 }
    .eight { grid-column-end: span 8 }
    .nine { grid-column-end: span 9 }
    .ten { grid-column-end: span 10 }
    .eleven { grid-column-end: span 11 }
}

@media only screen and (max-width: 720px) {

    .one\@m { grid-column-end: span 12 }
    .hide\@m {display: none !important}

}

//Utilities 
.margin-0 {
    margin: 0;
}

.margin-5 {
    margin: 5rem;
} 

.margin-top-0 {
    margin-top: 0;
} 

.margin-top-2 {
    margin-top: 2rem;
} 

.margin-top-5 {
    margin-top: 5rem;
} 


.padding-y-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

// Colors

.white { color: white}

/* ========= Forms ========= */


input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='color'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='datetime'],
input[type='datetime-local'],
input:not([type]),
textarea,
select {
	appearance: none;// Removes awkward default styles on some inputs for iOS
	background-color: transparent;
	border: 1px solid var(--primary-light);
	border-radius: .4rem;
	box-shadow: none;
	box-sizing: inherit; // Forced to replace inherit values of the normalize.css
	height: 2.8rem;
	padding: .6rem 1.0rem; // The .6rem vertically centers text on FF, ignored by Webkit
	width: 100%;

	&:focus {
		border-color: var(--primary);
        outline: 0
    }
}
select{
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="%23d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
	padding-right: 3.0rem;

	&:focus {
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="%239b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
    }
}

textarea{
    min-height: 6.5rem;
}

label,
legend {
	display: block;
	font-size: 1rem;
	margin-bottom: 1rem;
}

fieldset {
	border-width: 0;
	padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
	display: inline;
}

.label-inline {
	display: inline-block;
	font-weight: normal;
    margin-left: .5rem;
}





// Z Index

:root{
    --header-z-index: 10;
    --section-title__heading-z-index: 9;
}

.hidden {
    display: none !important;
}