.section-header {
  background: url(/img/hex.svg) top right no-repeat;
  background-size: cover;
  width: 100%;
}

header {
    background: var(--header-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;    
    height: 100px;
    z-index: var(--header-z-index);
}

nav {
    color: white;
    font-family: $global-font-family;
    font-weight: 400;
    display: flex;
    align-items: center;
    width:90%;
    margin: 0 auto;
}

.header--nav-list {
    list-style: none;
    display: flex;
    position: relative;
    margin-left:auto;
}

.header--list-item {
    margin-left: 2rem;
    text-transform: uppercase;
    height: 50px;
    transition: opacity 0.2s;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    opacity: 0.7;
    display: flex;
    align-items: center;
    z-index: var(--header-z-index);
}

.parent {
  pointer-events: none;
}

.header--list-item:hover{
  opacity: 1;
  cursor: pointer;
}

.header--list-item a.active {        
    opacity: 1;
}


.header--nav-drop {
  position: absolute;
  top:70px;
  background: rgba(255, 255, 255, 0.85);
  color: rgb(3, 21, 78);
  list-style: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  padding: 15px 7px;
  border-radius: 5px;
  z-index: var(--header-z-index);
}

.header--nav-drop a {
  text-shadow: none;
}

.header--nav-drop:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent  transparent  rgba(255, 255, 255, 0.85) transparent ;
  position: absolute;
  top: 0px;
  margin: -10px 0 0 20px;
}

.header--list-item:hover > ul { 
  top: 50px;
  visibility: visible;
  opacity: 1;
  z-index: var(--heaader-z-index);
}

.header--drop-item {
  padding: 0 15px;
  opacity: 1;
}

.header--drop-item a {
  display: block;
  width: 100%;
  line-height: 30px;
}

.header--drop-item:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}

[section-top] {
    background: url(/img/hex.svg) bottom right no-repeat;
    background-size: cover;
}

.logo {
  max-width: 169px;
  width: 100%;
  margin: auto 0;
}

.call-us {
    margin-left: 2rem;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    box-shadow: 0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08);
    transition: background-color 0.2s linear, opacity 0.2s linear;
    opacity: 0.7;
    min-width: 155px;
    line-height: 1.8;    
}

.call-us svg {
    margin-right: 15px;

}

.call-us svg path {
    stroke: white;
}

.call-us:hover {
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}

@include media("<=1000px") {
    .header--nav-list {
        display: none;
    }

    .call-us{
      margin-left: auto;
    }

    .hamburger {
      margin-left: 30px;
    }
}

@include media("<=small") {
    .call-us {
        display: none;
    }

    .hamburger {
      margin-left: auto;
    }

    [logo] {
        width: 125px;
    }

    header {
        height: 60px;
    }

}


@media screen and (min-width: 1088px) {
    [nds-hamburger] {
      display: none !important;
    }
}


/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
 .hamburger {
    /* padding: 15px 15px; */
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    /* border: 1px solid red; */
    /* margin: -7px 0 0 30px; */
    overflow: visible; }
    .hamburger:hover {
      opacity: 0.7; }
    .hamburger.is-active:hover {
      opacity: 0.7; }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
      background-color: #ffffff; }
  
  .hamburger-box {
    width: 20px;
    height: 12px;
    display: inline-block;
    position: relative; 
    }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 20px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -7px; }
    .hamburger-inner::after {
      bottom: -7px; }


/*
   * Vortex
   */
   .hamburger--vortex .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear; }
    .hamburger--vortex .hamburger-inner::before {
      transition-property: top, opacity; }
    .hamburger--vortex .hamburger-inner::after {
      transition-property: bottom, transform; }
  
  .hamburger--vortex.is-active .hamburger-inner {
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
      transition-delay: 0s; }
    .hamburger--vortex.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0; }
    .hamburger--vortex.is-active .hamburger-inner::after {
      bottom: 0;
      transform: rotate(90deg); }


@media screen and (min-width: 1000px) {
    .is-hidden-desktop {
        display: none !important;
    }

}


.offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.25s ease;
  background: rgba(23, 44, 116, 0.98);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: var(--header-z-index);

}

.is-open {
  transform: translateX(0);
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.offcanvas--nav {
  list-style: none; 
  padding: 30px 0;
}


.offcanvas--item {
  padding: 15px;  
}

.offcanvas--item:hover {
  color: white;  
  background: $primary;
}

.offcanvas--nav-drop {
  list-style: none;
  padding-left: 15px;
  margin-top: 15px;
}

.offcanvas--drop-item {
  margin-bottom:0;
  padding: 7px;
}

.offcanvas--drop-item a {
  display: block;
  width: 100%;
}

.offcanvas--drop-item:hover {
  background: rgba(255, 255, 255, 0.1);
}