[btn] {
    background: grey;
    color: #fff;
    font-size: 0.5em;
    padding: 0.5em 0.8em;
    border-radius: $global-border-radius;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08);
    transition: background 0.2s ease, transform 0.2s ease;
    letter-spacing: .05em;
}

[btn]:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}

[btn~="large"]  {
    font-size: 1.1em;
}


[btn~="primary"]  {
    background-color: #03154e;
}

[btn~="primary"]:hover  {
    background-color: #010b2c;
}


.btn {
    display: inline-block;
    background: grey;
    color: #fff;
    font-size: 1em;
    padding: 0.5em 0.8em;
    border: none;
    outline: none;
    border-radius: var(--global-border-radius);
    padding: 0.75em 2em;
    cursor: pointer;    
}

.btn--primary {
    background: linear-gradient(to top left, var(--primary), var(--primary-light));
}

.btn--primary:hover {
    background: linear-gradient(to top right, var(--primary), var(--primary-light));;
}