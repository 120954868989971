.list {
    list-style:none;
    padding-left: 1em;
}

.list-item{
    margin-bottom: 7px;
}

.list li::before {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right:0.5em;   
}

.list-check li::before {
    background-image: url(/img/icons/check.svg);
}


