.contact-link {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.contact-link [class*='icon-'] {
    margin-right:25px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: contain;
}

.icon-phone {
    background: url(/img/icons/receiver-primary.svg) no-repeat;    
}

.icon-facebook {
    background: url(/img/icons/facebook-primary.svg) no-repeat;
}

.icon-instagram {
    background: url(/img/icons/instagram.svg) no-repeat;
}